// app/javascript/controllers/emails_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "email"]

  add() {
    // Create a new email field div
    const emailField = document.createElement("div")
    emailField.setAttribute("data-emails-target", "email")
    emailField.innerHTML = `
      <div class="col-xs-11">
        <div class="form-group">
          <input type="text" name="contact[alternative_emails][]" placeholder="Enter email" class="form-control">
        </div>
      </div>
      <div class="col-xs-1">
        <button type="button" data-action="click->emails#remove">Remove</button>
      </div>
    `
    this.listTarget.appendChild(emailField)
  }

  remove(event) {
    // Remove the email field div
    event.target.closest("[data-emails-target='email']").remove()
  }
}
